//定义中文语言包
export default {
    index:{
        s1:"一分钟教程",
        s2:"干瞪眼规则",
        s3:"分享成为VIP",
        s31:"VIP可以设置牌桌背景,还享受其它优惠和便捷",
        s4:"玩家排行榜",
        s5:"牌局排行榜",
        s6:"建议/报告",
        s7:`这不是赌博网站。金币不能兑换现金。止于娱乐。`,
        s8:'金币',
        s9:'牌局数',
        s10:'金币',
        s11:'收益',
        s12:'日期',
        s13:'开始',
        s14:'教程',
        s15:'规则',
        s16:'排名',
        s17:'名字',
        s18:'更多',
        s19:'种类',
        s20:"德州扑克"
    },
    rules:{
        s0:"简介",
        s1:"干瞪眼是来自中国的扑克游戏。",
        s2:"玩家按照扑克点数依次出牌。比如,有人出了3,你只能接着出4,另一个人继续出5。",
        s3:"谁先把牌出完谁赢。",
        s4:"你可以按照以下方式出牌:",
        s5:"单牌",
        s6:"有人出了3,你只能接着出4,依次类推。如果没有4,可以出2。",
        s7:"2 可以终结任何其它单牌。炸弹可以终结单牌,包括2。",
        s8:"对子",
        s9:"有人出了对3,你只能接着出对4,依次类推。如果没有对4,可以出对2。",
        s10:"对2可以终结任何其它对子。炸弹可以终结所有对子。",
        s11:"顺子",
        s12:"有人出了456,你只能接着567,依次类推。",
        s13:"炸弹可以终结顺子。",
        s14:"炸弹",
        s15:"3个3,4个4等等,称为炸弹。炸弹可以终结单牌、对子、顺子。",
        s16:"3个4比3个3大,4个5比3个6大。2个王是最大的炸弹。也可以出3或4个王。",
        s17:"王",
        s18:"王可以替代其它任意一张牌。",
        s19:"以上的牌组合你可以认为是对4 和 顺子456。",
        s20:"你不能单独打出一张王，除非你手里只剩一张牌。",
        s21:"胜 / 负",
        s22:"谁先把牌出完谁赢。",
        s23:"其他人损失的金币数量,等于手里剩下的牌的数量乘以10。",
        s25:"其他人的损失,都作为胜者的奖励。",
        s26:"翻倍",
        s27:"如果有人出了一个炸弹,则胜负的金币翻倍。两个炸弹就乘以4,依此类推。",
        s28:"如果牌局结束,你一张牌都没出,你的损失会翻倍。",
        s29:"所以争取至少出一张牌。",
        s30:"底注",
        s31:"牌局开始时,你的金币会扣除100作为底注,等牌局结束底注会返还给你。"
    },
    login:{
        s1:"邮箱:",
        s2:"密码:",
        s3:"注册",
        s4:"登录",
        s5:"返回",
        s6:"昵称:",
        s7:"忘记密码?",
        s8:"注册后获得10K金币"
    },
    coinget:{
        s1:"您有金币:",
        s2:"点击按钮成为VIP。您将被赠与5000金币。VIP每年花费10美元。",
        s21:"现在可以通过分享游戏记录成为VIP。",
        s22:"详见这里",
        s2a:"VIP可以设置自己的牌桌背景画面,可以在tarneeb游戏中不限次数的查看牌堆。",
        s3:"点击按钮获取金币.每天你有一次机会获取5000金币,VIP可以获得6000。你的金币数量需少于",
        s4:"点击按钮购买金币,10000金币/3美元。VIP可以获得15000。"
    },
    share:{
        title1:"分享图片成为VIP",
        title11:"半年期vip",
        p11:"在fb,twitter,ytb等社交媒体上,分享你的牌局回放页面链接。",
        p12:"在牌局结束后,点击share按钮。截图会自动下载,链接也会自动放入剪贴板,可以直接复制。",
        p13:"将截图和链接放到 fb / twitter / ytb 上。然后将社交媒体的截图或地址发到fczx1999{'@'}gmail。最好使用注册邮箱发送。",
        p14:"我们收到邮件后,会赠送半年期VIP给你。",
        title2:"在youtube上分享视频",
        title22:"一年期vip",
        p21:"在youtube上分享牌局视频",
        p22:"可以在牌局回放页面点击Record按钮,然后点击'PLAY'按钮。牌局结束后,视频会被自动下载",
        p23:"注意需要使用chrome或edge浏览器。",
        p24:"将视频上传youtube。然后将youtube的截图或地址发到fczx1999{'@'}gmail。最好使用注册邮箱发送。",
        p25:"我们收到邮件后,会赠送一年期VIP给你。",
        title3:"只有50个用户有机会享受这个福利!"
    },
    rulestb:{
        s1:"tarneeb一共4个玩家,2个相对的玩家为一组。一共52张牌,没有大小王。开始每个玩家抓13张牌。",
        s2:"竞标阶段，游戏开始前先随机选择一名庄家,庄家右边的玩家先出价，然后逆时针依次出价。这里的出价就是表明我们这组可以赢得多少回合(总共13个回合)。出价最高的人为话事人，话事人可以选择哪种花色为王牌。",
        s3:"游戏开始后，话事人先出牌。第一个人出牌以后，其他人必须出相同花色的牌。谁出的牌最大，谁就赢得这个回合。",
        s4:"如果手里没有相同花色的牌，可以出其它花色的牌，但是其它花色的牌比同花色的牌要小。或者可以选择出王牌，王牌比同花色的牌大。",
        s5:"每一回合结束，上一回合的赢家先出牌。",
        s6:"游戏结束后，统计话事人那个小组赢得的回合数，是否达到他竞标时的出价。如果达到，话事人小组胜，否则另一个小组胜。",
        s7:"出价越高，胜利或失败后获得/失去的金币越多。赢/输的回合数越多，获得/失去的金币越多。",
    }
}