//定义阿拉伯语言包
export default {
    index:{
        s1:"دقيقة واحدة تعليمي",
        s2:"قواعد التحديق الجاف",
        s3:"مشاركة كبار الشخصيات",
        s31:"كبار الشخصيات يمكن تعيين خلفية الجدول ، ولكن أيضا التمتع غيرها من العروض و الراحة",
        s4:"قائمة اللاعبين",
        s5:"بطاقة الترتيب",
        s6:"توصيات",
        s7:`هذا ليس موقع القمار . الذهب لا يمكن استبدالها نقدا`,
        s8:'عملة ذهبية',
        s9:'عدد البطاقات',
        s10:'عملة ذهبية',
        s11:'دخل',
        s12:'تواريخ',
        s13:'بلاي ›',
        s14:'توتاليا',
        s15:'قواعد',
        s16:'رتب',
        s17:'الاسم',
        s18:'أكثر .',
        s19:'نوع .',
        s20:"سوها"
    },
    rules:{
        s0:"مقدمة",
        s1:"لعبة البوكر من الصين.",
        s2:"لاعب يلعب لعبة البوكر وفقا لعدد من النقاط . على سبيل المثال ، إذا كان شخص ما يخرج 3 ، يمكنك فقط الذهاب إلى 4 ، وآخر يذهب إلى 5 .",
        s3:"من يلعب البطاقة الأولى يفوز .",
        s4:"يمكنك اللعب بالطريقة التالية :",
        s5:"بطاقة واحدة",
        s6:"شخص ما يخرج 3 ، يمكنك فقط متابعة 4 ، وهلم جرا . إذا لم يكن هناك 4 ، يمكنك الخروج من 2 .",
        s7:"2 . يمكن إنهاء أي بطاقة واحدة أخرى . قنبلة يمكن أن تنتهي بطاقة واحدة ، بما في ذلك 2 .",
        s8:"أزواج",
        s9:"إذا كان شخص ما هو الحق في 3 ، يمكنك فقط متابعة الحق في 4 ، وهلم جرا . إذا كان هناك أي أزواج من 4 ، يمكنك الخروج من أزواج من 2 .",
        s10:"الزوج 2 يمكن إنهاء أي زوج آخر . القنابل يمكن أن تنتهي جميع أزواج .",
        s11:"شونكو",
        s12:"شخص ما يخرج من 456 ، يمكنك فقط متابعة 567 ، وهلم جرا .",
        s13:"القنبلة يمكن أن تنتهي .",
        s14:"قنبلة .",
        s15:"ثلاثة ، ثلاثة ، أربعة ، وهلم جرا ، تسمى القنابل .",
        s16:"ثلاثة أربعة إلى ثلاثة ثلاثة ، أربعة خمسة إلى ثلاثة ستة . الملك هو أكبر قنبلة . يمكنك أيضا الخروج مع الملوك 3 أو 4 .",
        s17:"كينج ›",
        s18:"ورقة رابحة يمكن أن تحل محل أي بطاقة أخرى .",
        s19:"مع مزيج من البطاقات المذكورة أعلاه يمكنك أن تنظر في أزواج 4 و 456 .",
        s20:"لا يمكنك لعب الملك وحده إلا إذا كان لديك بطاقة واحدة فقط في يدك .",
        s21:"فوز / خسرت",
        s22:"من يلعب البطاقة الأولى يفوز .",
        s23:"عدد القطع النقدية الذهبية المفقودة من قبل الآخرين يساوي عدد البطاقات المتبقية في اليد مضروبا في 10 .",
        s25:"خسائر الآخرين ، وكلها مكافآت للفائزين .",
        s26:"تضاعف",
        s27:"إذا كان شخص ما يجعل من قنبلة ، الذهب سوف يتضاعف . اثنين من القنابل مضروبة في أربعة ، وهلم جرا .",
        s28:"إذا كنت لا تلعب بطاقة واحدة في نهاية اللعبة ، سوف يتضاعف خسارتك .",
        s29:"لذا حاول أن تلعب بطاقة واحدة على الأقل .",
        s30:"ملاحظة أسفل",
        s31:"في بداية اللعبة ، سيتم خصم 100 دولار من الذهب الخاص بك ، في نهاية اللعبة سوف تعاد لك ."
    },
    login:{
        s1:"صندوق البريد:",
        s2:"كلمة السر:",
        s3:"التسجيل",
        s4:"سجل",
        s5:"رد : .",
        s6:"كنية:",
        s7:"نسيت كلمة السر ؟",
        s8:"الحصول على 10000 الذهب بعد التسجيل"
    },
    coinget:{
        s1:"لديك عملة ذهبية:",
        s2:"انقر على زر لتصبح كبار الشخصيات . سوف تعطى 5000 دولار من الذهب . كبار الشخصيات تنفق 10 دولار في السنة .",
        s21:'يمكنك أن تصبح كبار الشخصيات من خلال تقاسم اللعبة .',
        s22:'اضغط هنا',
        s2a:"كبار الشخصيات يمكن تعيين الجدول الخاص بهم خلفية الشاشة ، يمكنك في tarneeb اللعبة عدد غير محدود من عرض بطاقة كومة .",
        s3:"انقر على زر للحصول على الذهب كل يوم لديك فرصة للحصول على 5000 من الذهب ، كبار الشخصيات يمكن أن تحصل على 6000 . يجب أن يكون لديك أقل من الذهب",
        s4:"انقر على زر شراء الذهب ، 10 ، 000 الذهب / 3 دولار . يمكن لكبار الشخصيات الحصول على 15000 ."
    },
    share:{
        title1:"مشاركة الصور تصبح كبار الشخصيات",
        title11:"كبار الشخصيات",
        p11:"على الفيس بوك ، تويتر ، وغيرها من وسائل الاعلام الاجتماعية ، حصة الخاص بك بطاقة تشغيل الصفحة الروابط .",
        p12:"في نهاية اللعبة ، انقر على زر مشاركة . لقطات سيتم تحميلها تلقائيا ، وصلات تلقائيا في الحافظة ، ويمكن نسخها مباشرة .",
        p13:"وضع الصور و الروابط على الفيس بوك ، تويتر . ثم إرسال لقطات أو عناوين وسائل الاعلام الاجتماعية fczx1999{'@'}gmail منتدى فمن الأفضل استخدام تسجيل البريد الإلكتروني لإرسال .",
        p14:"بعد تلقي البريد الإلكتروني ، ونحن سوف نرسل لك نصف سنة كبار الشخصيات .",
        title2:"مشاركة الفيديو على يوتيوب",
        title22:"كبار الشخصيات",
        p21:"تقاسم الفيديو على يوتيوب",
        p22:"يمكنك النقر على ' إعادة ' زر في صفحة اللعب ، ثم انقر على ' اللعب ' . بعد المباراة ، يتم تحميل الفيديو تلقائيا .",
        p23:"لاحظ الحاجة إلى استخدام كروم أو حافة المتصفح .",
        p24:"تحميل الفيديو على يوتيوب . ثم إرسال لقطة من يوتيوب أو عنوان إلى fczx1999{'@'}gmail . فمن الأفضل استخدام تسجيل البريد الإلكتروني لإرسال ",
        p25:"عندما نتلقى البريد الإلكتروني ، ونحن سوف اعطيكم كبار الشخصيات لمدة سنة واحدة .",
        title3:"فقط 50 من المستخدمين لديهم الفرصة للاستمتاع بهذا الاستحقاق"
    },
    rulestb:{
        s1:'يلجأُ البعض في أوقات الفراغ لممارسة هوايات أو ألعاب لتسلية أنفسهم، أو قضاء أوقاتهم، خاصة جيل الشباب وكبار السن، منهم من يمارس لعبة الشطرنج، ومنهم من يلعب الطاولة، أو الألعاب الورقية باستخدام ورق اللعب المعروف بالـ"الشدة"، وهوايات ورياضات أخرى عديدة، سنتكلم في هذا المقال عن تقضية الوقت باستخدام ورق اللعب، تحديدًا لعبة الطرنيب.',
        s2:"لعبة الطرنيب هي إحدى أنواع الألعاب الورقية المشهورة، فهي مسلية وممتعة وتساعد على تنمية القدرات الذهنية من خلال عملية الحساب والتوقع، تتطلب ذكاءً ومهارةً ويقظة في اللعب. سنشرح طريقة اللعب ونوضح خفايا وكيفية الفوز بهذه اللعبة. فيديو قد يعجبك:",
        s3:"تلعب الطرنيب باستخدام نصف أوراق اللعب، أي باستخدام إمّا اللون الأزرق أو الأحمر، وتعداده اثنتين وخمسون ورقة لعب، نستثني الجواكر لأنّهم لا محل لهم في اللعبة. تحتاج هذه اللعبة لأربعة لاعبين لا أقل، يجلس كل اثنين مقابل بعضهم البعض، ليكونوا فريقًا، فيما يكون اللاعبين الآخرين الفريق الخصم. لبدء توزيع الأوراق وتحديد من يبدأ التوزيع، يقوم كلٌ منهم بسحب ورقة عشوائية من أوراق اللعب، صاحب الورقة الأقل قيمة هو من يبدأ التوزيع. يقوم بتوزيع الورق من اليمين إلى اليسار، موزعًا 13 بطاقة لعب على كل منهم. من الجدير بالذكر أنّ الشخص الجالس على يساره يجب أن يحدد له كيفية التوزيع، إمّا أن يطلب منه توزيع الورق عليه وعلى زميله أولًا، أو أخيرًا، أو ثانيًا وثالثًا، وقد يسمح له بالتوزيع مباشرة دون اختيار.",
        s4:'يتم تجميع الأوراق من اللون الواحد معًا، بالترتيب التصاعدي، وذلك ليسهل عليه تحديد نوع الطرنيب خاصتّه ويطلب ما يشاء. يقع الدور على الجالس يمين الموزع بالطلب أولًا، فيطلب عددًا مبدوءًا بـ 7 فصاعدًا حتى 11، وأعلى من ذلك يتوجب عليه أن يطلب "كبود" وهو عدد المجموعات التي سيكسبها خلال اللعبة مع كل دور لعب. يتم الطلب بالتتالي منه ثم الشخص الذي يليه، وصاحب الطلبة الأعلى يحدد نوع طرنيبه إن كان ديناريًا، أو بستونيًا، أو من نوع الكبة، أو الإسباتي. أعلى ورقة بين أوراق اللعب هي ورقة الآص، نزولًا بالشيخ ثم البنت ثم الولد، ثم تنازليًا من العشرة حتى الإثنين. تستمر اللعبة بتجميع نقاط كل فريق حتى يصل أحدهم إلى مجموع نقاط من 61 نقطة. يجمع لكل فريق ما طلب من الطرنيب واستطاع النجاح في الحصول عليه، وفي حالة عدم استطاعته ذلك يتم خصم مقدار خسارته من مجموع نقاطه، ويتم إضافة المجموعات التي كسبها الفريق الخصم إلى رصيد نقاطه. في حالة حدوث خطأ في توزيع أوراق اللعب، أو غش أثناء اللعبة يتم خصم 5 نقاط في الفريق المخطئ أو الذي قام بالغش.',
        s5:"يبدأ اللعب عند صاحب أعلى رقم في الطلب، الأولوية لصاحب الطلب أن يجمع الطرنيب من أيدي اللاعبين بحيث لا يستطيعوا كسب أي مجموعة أثناء اللعب إن لم يكن معه أي من اللون الذي لُعب، حيث أنّ نوع الطرنيب يربح المجموعة إن لم يكن بحوزة اللاعب أوراقًا من نوع المجموعة التي لعبت.",
        s6:"",
        s7:"أعلى عرض ، والمزيد من القطع النقدية سوف يفوز . أكثر الحيل يمكنك الفوز ، والمزيد من القطع النقدية يمكنك الفوز .",
    }
}