import bomb_sound from '@/assets/sounds/bomb.mp3'

//日期格式转换
export const date_format = (dataString, lang = "zh-CN") => {
    //dataString是整数，否则要parseInt转换
    var time = new Date(dataString);
    var year = time.getFullYear();
    var month = time.getMonth() + 1;
    var day = time.getDate();
    if (lang == "en") {
        return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
    } else {
        return year + '年' + (month < 10 ? '0' + month : month) + '月' + (day < 10 ? '0' + day : day) + '日';
    }
}


//获得今天日期格式
export const today_format = () => {
    //dataString是整数，否则要parseInt转换
    var time = new Date();
    var year = time.getFullYear();
    var month = time.getMonth() + 1;
    var day = time.getDate();
    return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
}

//获得今天日期格式 加上时分秒
export const now_format = () => {
    //dataString是整数，否则要parseInt转换
    var time = new Date();
    var year = time.getFullYear();
    var month = time.getMonth() + 1;
    var day = time.getDate();
    var hour = time.getHours();
    var minute = time.getMinutes();
    return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + " " + hour + ":" + minute;
}

//返回随机字符串，长度为len
//year为true，则前面加上当前年份
export const random_string = (len, year = false, suff = ".jpg") => {
    let s = ""
    len = len || 32
    let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    let maxPos = chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    if (year) {
        let date = new Date()
        let datestr = date.getYear()
        s = datestr + "_" + pwd
    } else {
        s = pwd
    }
    s += suff
    return s
}

//判断是否手机
export function isMobile() {
    var userAgentInfo = navigator.userAgent;

    var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

    var mobile_flag = false;

    //根据userAgent判断是否是手机
    for (var v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
            mobile_flag = true;
            break;
        }
    }

    var screen_width = window.screen.width;
    var screen_height = window.screen.height;

    //根据屏幕分辨率判断是否是手机
    if (screen_width < 600) {
        mobile_flag = true;
    }

    return mobile_flag;
}

//判断是否IPHONE
export function isIphone() {
    return /iPhone/.test(navigator.userAgent);
}

//判断是否safari
export function isSafari() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
            return false // Chrome
        } else {
            return true // Safari
        }
    }
    return false
}

//进入全屏
// export function requestFullScreen() {
//     if(!isMobile())return false;
//     var de = document.documentElement;
//     if (de.requestFullscreen) {
//         de.requestFullscreen();
//     } else if (de.mozRequestFullScreen) {
//         de.mozRequestFullScreen();
//     } else if (de.webkitRequestFullScreen) {
//         de.webkitRequestFullScreen();
//     }
// }


export function requestFullScreen() {
    if (!isMobile()) return false;
    var e = document.documentElement;
    if (e.requestFullscreen) {
        e.requestFullscreen();
    }/*FireFox */ else if (e.mozRequestFullScreen) {
        e.mozRequestFullScreen();
    }/*Chrome等 */ else if (e.webkitRequestFullScreen) {
        e.webkitRequestFullScreen();
    }/*IE11*/ else if (e.msRequestFullscreen) {
        e.msRequestFullscreen();
    }/* 适配safari */else {
        document.styleSheets[0].insertRule(".UserDefinedFullscreenElement{position:fixed !important;top:0px !important;left:0px !important;height:100% !important;width:100% !important;z-index:9999999 !important;}");
        document.userDefinedFullscreenElement = e;
        e.className += " UserDefinedFullscreenElement";
        // window.addEventListener("keydown", function (ev) {
        //     if (ev.key === "Escape") {
        //         toggleFullScreen(e);
        //     }
        // });
    }
}



//退出全屏
export function exitFullscreen() {
    if (!isMobile()) return false;
    var de = document;
    if (de.exitFullscreen) {
        de.exitFullscreen();
    } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
    } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
    }
}


//拷贝内容到剪贴板
export function copyToClip(content) {
    console.log("复制的内容", content);
    var ele = document.createElement("input"); //创建一个input标签
    ele.setAttribute("value", content); // 设置改input的value值
    document.body.appendChild(ele); // 将input添加到body
    ele.select();  // 获取input的文本内容
    document.execCommand("copy"); // 执行copy指令
    document.body.removeChild(ele); // 删除input标签
}

//发出声音
export function makeSound(name) {
    switch (name) {
        case "bomb":
            // let music = new Audio(bomb_sound)
            // music.loop = false
            // music.play()
            break
        default:
            return
    }
}

//数字格式化
export function number_format(num) {
    if (typeof num !== 'number') {
        return num
    }
    let num_int = parseInt(num)
    if (-1000 < num_int && num_int < 1000) return num_int
    let num_k_float, num_k_int
    if (100000 > num_int && num_int >= 1000) {
        num_k_float = (parseFloat(num_int) / 1000.0).toFixed(1)
        return num_k_float + "K"
    }
    if (1000000 > num_int && num_int >= 100000) {
        num_k_int = parseInt(num_int / 1000)
        return num_k_int + "K"
    }
    if (-100000 < num_int && num_int <= -1000) {
        num_k_float = (parseFloat(num_int) / 1000.0).toFixed(1)
        return num_k_float + "K"
    }
    if (-1000000 < num_int && num_int <= -100000) {
        num_k_int = parseInt(num_int / 1000)
        return num_k_int + "K"
    }

    let num_m_float, num_m_int
    if (100000000 > num_int && num_int >= 1000000) {
        num_m_float = (parseFloat(num_int) / 1000000.0).toFixed(1)
        return num_m_float + "M"
    }
    if (1000000000 > num_int && num_int >= 100000000) {
        num_m_int = parseInt(num_int / 1000000)
        return num_m_int + "M"
    }
    if (-100000000 < num_int && num_int <= -1000000) {
        num_m_float = (parseFloat(num_int) / 1000000.0).toFixed(1)
        return num_m_float + "M"
    }
    if (-1000000000 < num_int && num_int <= -100000000) {
        num_m_int = parseInt(num_int / 1000000)
        return num_m_int + "M"
    }

    let num_g_float, num_g_int
    if (100000000000 > num_int && num_int >= 1000000000) {
        num_g_float = (parseFloat(num_int) / 1000000000.0).toFixed(1)
        return num_g_float + "G"
    }
    if (1000000000000 > num_int && num_int >= 100000000000) {
        num_g_int = parseInt(num_int / 1000000000)
        return num_g_int + "G"
    }
    if (-100000000000 < num_int && num_int <= -1000000000) {
        num_g_float = (parseFloat(num_int) / 1000000000.0).toFixed(1)
        return num_g_float + "G"
    }
    if (-1000000000000 < num_int && num_int <= -100000000000) {
        num_g_int = parseInt(num_int / 1000000000)
        return num_g_int + "G"
    }


    if (-100000000000000 < num_int && num_int < 100000000000000) {
        let num_t_float = (parseFloat(num_int) / 1000000000000.0).toFixed(1)
        return num_t_float + "T"
    }

    let num_t_int = parseInt(num_int / 1000000000000)
    return num_t_int + "T"
}

//等待多个图片加载完成
export async function loadImages(imageUrlArray) {
    const promiseArray = []; // create an array for promises
    const imageArray = []; // array for the images

    for (let imageUrl of imageUrlArray) {

        promiseArray.push(new Promise(resolve => {

            const img = new Image();
            // if you don't need to do anything when the image loads,
            // then you can just write img.onload = resolve;

            img.onload = function () {
                // do stuff with the image if necessary

                // resolve the promise, indicating that the image has been loaded
                resolve();
            };

            img.src = imageUrl;
            imageArray.push(img);
        }));
    }

    await Promise.all(promiseArray); // wait for all the images to be loaded
    console.log("all images loaded");
    return imageArray;
}

//等待单个文件加载完成
export async function loadImage(imageUrl) {
    let img;
    const imageLoadPromise = new Promise(resolve => {
        img = new Image();
        img.onload = resolve;
        img.src = imageUrl;
    });

    await imageLoadPromise;
    console.log("image loaded");
    return img;
}


// 将 base64 转换为 Blob
export function base64ToBlob(base64Data) {
    //console.log(base64Data);//data:image/png;base64,
    var byteString;
    if (base64Data.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(base64Data.split(',')[1]);//base64 解码
    else {
        byteString = unescape(base64Data.split(',')[1]);
    }
    var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];//mime类型 -- image/png

    // var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
    // var ia = new Uint8Array(arrayBuffer);//创建视图
    var ia = new Uint8Array(byteString.length);//创建视图
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ia], {
        type: mimeString
    });
    return blob;
}


//数组求和
export function arrsum(arr) {
    let s = 0;
    for (let i = arr.length - 1; i >= 0; i--) {
        s += arr[i];
    }
    return s;
}

//求数组最大值下标
export function getIndexMax(arr) {
    const maxNum = Math.max(...arr)
    const maxIndex = arr.indexOf(maxNum)
    return maxIndex
}


//判断两个数组是否相等
export function array_equal(a, b) {
    if (!Array.isArray[a] || !Array.isArray[b]) return false
    if (a.length != b.length) return false
    for (let i = 0; i < a.length; i++) {
        if (a[i] != b[i]) return false
    }
    return true
}

//将数字转为扑克
export const num_to_poker = (n) => {
    switch (n) {
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
            return n
        case 11:
            return 'J'
        case 12:
            return 'Q'
        case 13:
            return 'K'
        case 14:
            return 'A'
    }
}

//数组相减  arr1-arr2
export const arrSubtract = (arr1, arr2) => {
    let arr3 = arr1.filter(el => !~arr2.indexOf(el))
    return arr3
}

//某张牌在尚未出的牌中排第几  排第一的话返回0 第二返回1   有几张比这个大
export const getCardrank = (cardn, cards) => {
    let cards0 = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
    let cards2 = arrSubtract(cards0, cards)
    let cards_ = cards2.filter(v => v > cardn)
    return cards_.length
}
