//定义英文语言包
export default {
    index:{
        s1:"One minute tutorial",
        s2:"Rules of round-eyed",
        s3:"Share to become VIP",
        s31:"VIP can set table background image,can also enjoy some conveniences",
        s4:"Players Ranking",
        s5:"Games Ranking",
        s6:"Advice/Report",
        s7:`This is not a gambling web. Coins can't be exchanged for cash. Just for fun!`,
        s8:'Coins',
        s9:'Number',
        s10:'Coins',
        s11:'Profit',
        s12:'Date',
        s13:'Play',
        s14:'Tutarial',
        s15:'Rule',
        s16:'Rank',
        s17:'Name',
        s18:'more',
        s19:'Game',
        s20:"Texas Hold'em"
    },
    rules:{
        s0:"Overview",
        s1:"Round-eyed is a poker game from China, originally named Gan Deng Yan.",
        s2:"Players play cards based on figures.E.g.,someone play 3,you must play 4, then another person should play 5.",
        s3:"The one who play all his/her cards first is winner.",
        s4:"You can play cards in the following ways:",
        s5:"Single card",
        s6:"Someone play 3,you must play 4,and so on.If you don't have 4,you can play 2.",
        s7:"2 can end any other single card.Bomb can end any single card.",
        s8:"Pair",
        s9:"Someone play 33,you must play 44,and so on.If you don't have 44,you can play 22.",
        s10:"22 can end any other pair.Bomb can end any pair.",
        s11:"Straight",
        s12:"Someone play 456,you must play 567,and so on.",
        s13:"Bomb can end any straight.",
        s14:"Bomb",
        s15:"Cards like 333,4444 are bombs.Bombs can end any single card / pair / straight.",
        s16:"444 can end 333,5555 can end 666. Two Jokes can end any other bomb,so can three jokes.",
        s17:"Joker",
        s18:"Joker can replace any other single card.",
        s19:"You can consider the cards above as pair 4 and straight 456.",
        s20:"But you can never play a joker as single card,unless you only have one card.",
        s21:"Win / Lose",
        s22:"The one who play all his/her cards first is winner.",
        s23:"Other players are loser.Loser will lose coins equal to the number of her/his left cards multiplied by 10.",
        s25:"All losses of the losers belong to the winners.",
        s26:"Double",
        s27:"If a bomb appears in the game,everyone's winnings or losses will be doubled.If two bombs, that will befour times.",
        s28:"When the game ends, if you haven't played a card,your losses will be doubled.",
        s29:"So you should strive to play at least one card",
        s30:"Ante",
        s31:"Once the game begins,100 coins will be deducted for ante,they wiil be return back at the end of game."
    },
    login:{
        s1:"Email:",
        s2:"Password:",
        s3:"Register",
        s4:"Login",
        s5:"Home",
        s6:"Nickname:",
        s7:"Forget password?Click here",
        s8:"Get 10K coins after registration"

    },
    coinget:{
        s1:"Your coins:",
        s2:"Click button left to become VIP.You will receive 5000 coins at once. It costs $10 for one year.",
        s21:"You can become VIP by sharing games.",
        s22:"click here",
        s2a:"VIP can set table background image,also can view history without limit in tarneeb.",
        s3:"Click button left to get Relief funds.You can get a relief fund of 5000 coins(Vip can get 6000) for free once a day.Your coins should less than",
        s4:"10000 coins for $3.Vip can get 15000."
    },
    share:{
        title1:"Share picture and gameReplay link url",
        title11:"vip for half year",
        p11:"Share the link of your games replay, to any social media like fb,twitter,ytb,ins etc.",
        p12:"You can click 'share' button after game.Then the screenshot will be download,and link url will be put on clipboard.",
        p13:"You can upload them on your fb / twitter / ytb.Then send screenshot/link to fczx1999{'@'}gmail.You'd better use your register email.",
        p14:"After we receive the mail, we will elevate you to VIP for half year.",
        title2:"Share video on youtube",
        title22:"vip for one year",
        p21:"Share the video of your games replay on youtube.",
        p22:"You can click 'Record' button on game replay page.Then click 'PLAY' button.After game over,video will be download.",
        p23:"You should use chrome browser,or edge.",
        p24:"You can upload video on youtube.Then send screenshot/link to fczx1999{'@'}gmail.You'd better use your register email.",
        p25:"After we receive the mail, we will elevate you to VIP for one year.",
        title3:"Only 50 users can get this opportunity!"
    },
    rulestb:{
        s1:"Tarneeb is a trick taking card game which is currently very popular in the Middle East as well as various areas of Africa.The game's name translates to Trump in Arabic, which describes one of the primary features of the game,use of a trump suit.The game is sometimes also called Tarnib, Tarnibe and Hakam.",
        s2:"Tarneeb is designed to be played by four players playing in two partnerships consisting of two players each.The game is played using one standard 52 card deck with the cards ranking as follows (from high to low);Ace, King, Queen, Jack, 10, 9, 8, 7, 6, 5, 4, 3, 2. If partnerships have not been predetermined the participants in the game may use various methods to determine the partnerships for the game.One common method is to have all players draw from the shuffled deck, and the players drawing the two highest ranked cards play as partners against the players drawing the two lowest ranked.The partners should seat themselves in such a way that they sit directly opposite each at the table. Theplayer drawing the highest card of all is usually set as the first dealer.",
        s3:"After the partnerships and first dealer is determined, the dealer shuffles the cards with the player to the dealer's immediate left cutting the deck.The dealer then distributes the entire deck, such that each player will receive a hand consisting of 13 face down cards.The play, deal and rotation of the dealer role all rotate in a counter-clockwise direction.",
        s4:"Example Bidding in TarneebOnce the players receive and examine their cards, the bidding portion of the game begins.The player to the immediate right of the current dealer has the first opportunity to bid.A bid is a player's estimate of the number of tricks he believes he can win with the aid of his partner.The minimum bid a player may make is seven. A player may also pass rather than bid, however each player only has one opportunity to bid on each hand.Each bid must be higher than any previous bid during the hand to be considered a legal bid.The player who makes the highest bid during the bidding round is called the Declarer for the hand.The one exception to this rule is that the last player to bid, the dealer, has the option to make a bid equal (rather than higher) to the current high bid and become the Declarer for the hand.After the Declarer is determined, this player then states the trump suit to be used during the hand.If all four players pass, the cards are thrown in, shuffled and redealt by the same dealer.",
        s5:"After the bidding the play of the hand begins. The Declarer (also sometimes known as the Contractor) for the hand begins play, playing any card from his hand to start the first trick.On each trick, a player must play a card of the suit led to the trick if they have one.If they do not have such a card, they may play any card from their hand, including a card of the trump suit (if they have one).After all players have played one card to the trick, it is determined who wins the trick.The highest trump card played to the trick wins the trick. However, if the trick contains no card of the trump suit, the highest card of the suit originally led to that trick wins it.One player from each partnership should collect and gather all tricks won by his team in a face down pile.On each trick after the first, the winner of the previous trick leads the first card.",
        s6:"After all 13 tricks have been played, the Declarer's team then checks the number of tricks won to determine if they were able to win at least as many tricks as bid.If so, they earn a score equal to the number of tricks won during the hand. However, if they were unable to win at least as many tricks as bid, they must subtract from their current score an amount equal to their bid on the hand.In this case, the opposing team also earn a score equal to the number of tricks they have managed to win during the hand. Teams may have negative scores during this game, which is common.",
        s7:"The higher you bid, the more coins you win/loss.The more tricks you win/loss,the more coins you win/loss.",
    }
}